
const initialState = {
    loading: false
}

const PhysiotherapistReducer = (state = initialState, action) => {
    if (action.type.includes("UPHYSIOTHERAPIST") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("UPHYSIOTHERAPIST") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "UPHYSIOTHERAPIST_UPDATE_SUCCESS":
                return { ...state, loading: false }
            default:
                return {...state}
        }
    }
}

export default PhysiotherapistReducer