const initialState = {
    loading: false,
    record: null,
    registerations: [],
    registerations_filter: [],
    registerations_items: 1,
    patients: [],
    patients_filter:[],
    patients_medcloud: [],
    patients_medcloud_filter: [],
    patients_trustlab:[],
    patients_trustlab_filter:[],
    patients_trinity:[],
    patients_trinity_filter:[],
    patients_sehaty:[],
    patients_sehaty_filter:[],
    patients_espitalia:[],
    patients_espitalia_filter:[],
    items:1,
    items_medcloud:1,
    items_trustlab:1,
    items_trinity:1,
    items_sehaty:1,
    items_espitalia:1,
    err: null,
    registers:[]
}

const registeralReducer = (state = initialState, action) => {
    if (action.type.includes("REGISTERAL") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("REGISTERAL") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "GET_REGISTERAL_PATIENTS_SUCCESS":
                return {...state, loading: false, patients: action.data, items: action.items}
            case "REGISTERS_SUCCESS":
                return {...state, loading: false, registers: action.data}
                
            case "GET_REGISTERAL_MEDCLOUD_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_medcloud: action.data, items_medcloud: action.items}
            case "GET_REGISTERAL_TRUSTLAB_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_trustlab: action.data, items_trustlab: action.items}
            case "GET_REGISTERAL_TRINITY_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_trinity: action.data, items_trinity: action.items} 
            case "GET_REGISTERAL_SEHATY_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_sehaty: action.data, items_sehaty: action.items} 
            case "GET_REGISTERAL_ESPITALIA_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_espitalia: action.data, items_espitalia: action.items}           
            case "SEARCH_REGISTERAL_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_filter: action.data}
            case "SEARCH_REGISTERAL_MEDCLOUD_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_medcloud_filter: action.data}
            case "SEARCH_REGISTERAL_TRUSTLAB_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_trustlab_filter: action.data}
            case "SEARCH_REGISTERAL_TRINITY_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_trinity_filter: action.data}
            case "SEARCH_REGISTERAL_SEHATY_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_sehaty_filter: action.data}
            case "SEARCH_REGISTERAL_ESPITALIA_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_espitalia_filter: action.data}   
            case "GET_REGISTERAL_DATA_SUCCESS":
                return {...state, loading: false, registerations: action.data, registerations_items: action.items}   
            case "SEARCH_REGISTERAL_DATA_SUCCESS":
                return {...state, loading: false, registerations_filter: action.data, registerations_items: action.items} 
            case "GET_REGISTERAL_RECORD_SUCCESS":
                return {...state, loading: false, record: action.data}      
            default:
                return {...state}
        }
    }
}

export default registeralReducer
