const initialState = {
  loading: false,
  data: [],
  data_filter: [],
  phones: [],
  items:1,
  err: null
}

const medicalNetworkReducer = (state = initialState, action) => {
  if (action.type.includes("MEDICALNETWORK") && action.type.includes("REQUEST")) return {...state, loading: true}
  else if (action.type.includes("MEDICALNETWORK") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
  else {
      switch (action.type) {
          case "MEDICALNETWORK_SEARCH_SUCCESS":
              return {...state, loading: false, data: action.data, items: action.items}
          case "PATIENT_GET_ENTITY_PHONE_SUCCESS":
            return {...state, loading: false, phones: action.data} 
          default:
              return {...state}
      }
  }
}

export default medicalNetworkReducer
