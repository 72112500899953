const initialState = {
    loading: false,
    new_patient: null,
    patients: [],
    patients_filter: [],
    patients_online:[],
    patients_generated:[],
    patients_registered:[],
    items:1,
    registered_phones: [],
    excelFile: null,
    err: null
}

const adminReducer = (state = initialState, action) => {
    if (action.type.includes("ADMIN") && action.type.includes("REQUEST")) return {...state, loading: true}
    else if (action.type.includes("ADMIN") && action.type.includes("FAILED")) return {...state, err: action.err, loading: false}
    else {
        switch (action.type) {
            case "GET_ADMIN_PATIENTS_SUCCESS":
                return {...state, loading: false, patients: action.data, items: action.items} 
            case "GET_ADMIN_PATIENTS_ONLINE_SUCCESS":
                return {...state, loading: false, patients_online: action.data}   
            case "GET_ADMIN_PATIENTS_REGISTERED_SUCCESS":
                return {...state, loading: false, patients_registered: action.data}
            case "GET_ADMIN_PATIENTS_GENERATED_SUCCESS":
                return {...state, loading: false, patients_generated: action.data}    
            case "SEARCH_ADMIN_PATIENTS_SUCCESS":
                return {...state, loading: false, patients_filter: action.data}
            case "ADMIN_CREATE_QRCODE_SUCCESS":
                return {...state, loading: false, new_patient: action.data}
            case "ADMIN_BULK_QRCODE_SUCCESS":
                return {...state, loading: false, excelFile: action.data}
            case "ADMIN_GET_ENTITY_PHONE_SUCCESS":
                return {...state, loading: false, registered_phones: action.data}
            default:
                return {...state}
        }
    }
}

export default adminReducer
